// extracted by mini-css-extract-plugin
export var Footer = "style-module--Footer--209f7";
export var address = "style-module--address--d1415";
export var body = "style-module--body--ced68";
export var caseBody = "style-module--caseBody--13c09";
export var caseByLine = "style-module--caseByLine--f2860";
export var caseHeadline = "style-module--caseHeadline--baa4a";
export var caseMeta = "style-module--caseMeta--b1c4e";
export var caseSmall = "style-module--caseSmall--14aeb";
export var caseSubheadline = "style-module--caseSubheadline--5da74";
export var container = "style-module--container--26957";
export var links = "style-module--links--88bd7";
export var mini = "style-module--mini--076b1";
export var nav = "style-module--nav--97417";
export var socials = "style-module--socials--db8fb";
export var tag = "style-module--tag--40726";
export var title = "style-module--title--3bf98";