import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as styles from './style.module.scss'
import parse from 'html-react-parser'
import uniqid from 'uniqid'
import ig from '~images/ig.svg'
import li from '~images/linkedin.svg'
import classNames from 'classnames'

import Container from '~globals/Container'

const Footer = () => {

  const { allPrismicPage, prismicFooter } = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicPage {
        edges {
          node {
            data {
              slug
              navigation_name {
                text
              }
            }
            prismicId
          }
        }
      }
      prismicFooter {
        data {
          address {
            html
          }
          instagram {
            url
            target
          }
          nav {
            link {
              id
            }
          }
          linkedin {
            url
            target
          }
        }
      }
    }
  `)

  const pages = allPrismicPage.edges
  const { address, instagram, linkedin, nav } = prismicFooter.data

  return (
    <footer className={styles.Footer}>
      <Container extraClassName={styles.container}>
        <div className={classNames(styles.address)}>
          {parse(address.html)}
        </div>
        <div className={styles.links}>
          <div className={styles.socials}>
            {linkedin?.url && (
              <a
                data-umami-event="linkedin-footer"
                href={linkedin.url}
                target={linkedin.target}
                rel="noopener noreferrer"
              >
                <img src={li} alt="LinkedIn icon" />
              </a>
            )}
            {instagram?.url && (
              <a
                data-umami-event="instagram-footer"
                href={instagram.url}
                target={instagram.target}
                rel="noopener noreferrer"
              >
                <img src={ig} alt="Instagram icon" />
              </a>
            )}
          </div>
          <nav className={styles.nav}>
            <ul>
              {nav && nav.map(({ link }) => {
                const pageLink = pages.filter(item => item.node.prismicId === link.id)
                return (
                  <li key={uniqid()}>
                    <Link to={`/${pageLink[0].node.data.slug}`}>
                      {pageLink[0].node.data.navigation_name.text}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </Container>
    </footer>
  )
}



export default Footer