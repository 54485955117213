import React from 'react'
import Footer from '~components/globals/Footer'

const Layout = ({ children, location }) => {

  return (
    <>
      <main>{children}</main>
      <Footer location={location} />
    </>
  )
}

export default Layout
