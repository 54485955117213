import React from 'react'
import classNames from 'classnames'

import * as styles from './style.module.scss'

const Container = ({ children, extraClassName, innerRef }) => {
  const classes = classNames(styles.container, extraClassName)
  return (
    <div ref={innerRef} className={classes}>
      {children}
    </div>
  )
}

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <Container innerRef={ref} {...props} />)
